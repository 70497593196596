// taken from https://github.com/TanStack/query/discussions/4943#discussioncomment-6870472
import { useCallback, useEffect, useState } from 'react';

type QueuedTask<T> = {
  id: string;
  task: () => Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (e: unknown) => void;
  processing: boolean;
};

export const useConcurrentTaskQueue = <T>(maxConcurrency: number) => {
  const [taskQueue, setTaskQueue] = useState<QueuedTask<T>[]>([]);

  useEffect(() => {
    if (
      taskQueue.filter(t => !t.processing).length > 0 &&
      taskQueue.filter(t => t.processing).length < maxConcurrency
    ) {
      const nextTask = taskQueue.find(t => !t.processing);
      if (nextTask) {
        const withProcessing = { ...nextTask, processing: true };

        setTaskQueue(q => [
          ...q.filter(t => t.id !== nextTask.id),
          withProcessing,
        ]);

        nextTask
          .task()
          .then(result => {
            nextTask.resolve(result);
          })
          .catch(e => {
            nextTask.reject(e);
          })
          .finally(() => {
            setTaskQueue(q => q.filter(i => i.id !== nextTask.id));
          });
      }
    }
  }, [taskQueue, maxConcurrency]);

  const executeConcurrentTask = useCallback(async (task: () => Promise<T>) => {
    const id = crypto.randomUUID();

    return new Promise<T>((resolve, reject) => {
      setTaskQueue(q => [
        ...q,
        { id, task, resolve, reject, processing: false },
      ]);
    });
  }, []);

  return { executeConcurrentTask, taskQueue };
};
